import { render, staticRenderFns } from "./AnnouncementList.vue?vue&type=template&id=5a903b45&scoped=true"
import script from "./AnnouncementList.vue?vue&type=script&lang=js"
export * from "./AnnouncementList.vue?vue&type=script&lang=js"
import style0 from "./AnnouncementList.vue?vue&type=style&index=0&id=5a903b45&prod&lang=scss&scoped=true"
import style1 from "./AnnouncementList.vue?vue&type=style&index=1&id=5a903b45&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a903b45",
  null
  
)

export default component.exports